import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextLight, TextMedium } from "../../base/BaseText";

import BannerImage from "../../images/home/image-home-banner.svg";
import BannerImage1 from "../../images/home/image-home-banner-caro1.svg";
// import BannerImage2 from "../../images/home/image-home-banner-caro2.svg";
// import BannerImage3 from "../../images/home/image-home-banner-caro3.svg";

import BannerImage2New from "../../images/home/new-banner-2.png";
import BannerImage3New from "../../images/home/new-banner-3.png";
import BannerImageStarterBatt from "../../images/home/new-banner-4.png";

// import DamagedPhoneBanner from "../../images/home/subBanner.svg";

import DamagedPhoneBannerNew from "../../images/home/content-main-image.png";

import BannerImageWithContent from "../../images/home/image-banner-content.svg";
import leftNav from "../../images/home/Button-carousel-left.svg";
import rightNav from "../../images/home/Button-carousel-right.svg";
// import BannerImageCelcom from "../../images/home/image-home-banner-celcom.svg";
import BannerImageCelcom from "../../images/v2/home/celcom/person.png";
import BaseButtonGroup from "../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../base/BaseButton/BaseButton";
import BaseLink from "../../base/BaseLink/BaseLink";
import BasePopup from "../../base/BasePopup/BasePopup";
// import DamagedPhoneImage from "../../images/home/image-damaged-phone.svg";
// import DamagedPhoneImageMobile from "../../images/home/image-damaged-phone-mobile.svg";
import DamagedPhoneImage from "../../images/v2/home/celcom/damaged-phone.png";
import DamagedPhoneImageMobile from "../../images/v2/home/celcom/damaged-phone.png";

import UserIcon from "../../images/home/icon-user.svg";
import RepairedScreenIcon from "../../images/home/icon-repaired-screen.svg";
import CrackedScreenIcon from "../../images/home/icon-cracked-screen.svg";
import CrackedScreenHandIcon from "../../images/home/icon-cracked-screen-hand.svg";
import IconPhone from "../../images/home/icon-phone.svg";
import IconPreparePhone from "../../images/home/icon-prepare-phone.svg";
import IconReturnRepairedPhone from "../../images/home/icon-return-repaired-phone.svg";
import IconPhoneDesign1 from "../../images/home/icon-phone-design1.svg";
import IconPhoneDesign2 from "../../images/home/icon-phone-design2.svg";
import BaseCard from "../../base/BaseCard/BaseCard";
import BaseTable, {
  BaseTD,
  // BaseTH,
  BaseTR,
} from "../../base/BaseTable/BaseTable";
// import SROffer from "../../images/home/image-sr-offer.svg";
import { useSelector } from "react-redux";
import { Translate, useTranslate } from "../../helpers/languageUtils";
import { CLIENT, LANGUAGE } from "../../helpers/constants";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useEffect } from "react";
import moment from 'moment';
import surIcon from "../../images/home/cracked-phone.png";

const HomeBody = ({ onGetStarted }) => {
  const celcomCampaignStartDate = moment(process.env.REACT_APP_CELCOM_CAMPAIGN_START || '2024-07-01', "YYYY-MM-DD");
  const celcomCampaignEndDate = moment(process.env.REACT_APP_CELCOM_CAMPAIGN_END || '2024-10-01', "YYYY-MM-DD");
  // const isCelcomCampaignEnded = moment().isSameOrAfter(celcomCampaignEndDate, "day")
  const isCelcomCampaignInactive = moment().isBefore(celcomCampaignStartDate, "day") || moment().isSameOrAfter(celcomCampaignEndDate, "day");
  const [showPopup, setShowPopup] = useState(false);
  const [showProgramsPopup, setShowProgramsPopup] = useState(false);
  const [showDeliveryTimeTablePopup, setShowDeliveryTimeTablePopup] =
    useState(false);
  const [showTutorialPopup, setShowTutorialPopup] = useState(false);
  const client = useSelector((state) => state.app.client);
  const language = useSelector((state) => state.app.language);
  const t = useTranslate();

  const isSingtel = client === CLIENT.SINGTEL;
  
  const surCampaignStart = isSingtel ? process.env.REACT_APP_SUR_CAMPAIGN_START : "";
  const surCampaignEnd = isSingtel ? process.env.REACT_APP_SUR_CAMPAIGN_END : "";
  const isSurCampaignEndDateActive = isSingtel ? moment().isSameOrBefore(moment(surCampaignEnd), "day") : false;
  const isSURCampaign = isSingtel ? process.env.REACT_APP_SUR_CAMPAIGN_ENABLED === 'true' && isSurCampaignEndDateActive : false;

  const battRepCampaignEnd = isSingtel ? process.env.REACT_APP_SINGTEL_BATT_CAMPAIGN_END : "";
  const isBattRepEndDateActive = isSingtel ? moment().isSameOrBefore(moment(battRepCampaignEnd), "day") : false;
  const isBattRepCampaign = isSingtel ? isBattRepEndDateActive : false;

  const langMap = {
    "en": "en-US",
    "my": "ms-MY"
  }
  const isMyAsurionRedirect = process.env.REACT_APP_IS_MYASURION_REDIRECT === "true";
  const myAsurionUrl = isMyAsurionRedirect ? `${process.env.REACT_APP_MYASURION_SUR}/login?lang=${langMap[language]}` : undefined;

  const isMyAsurionSingtelRedirect = process.env.REACT_APP_IS_SINGTEL_MYASURION_REDIRECT === "true";
  const myAsurionSingtelUrl = isMyAsurionSingtelRedirect ? `${process.env.REACT_APP_SINGTEL_MYASURION_SUR}/login` : undefined;

  // console.log("isSURCampaign", isSURCampaign)

  const [showSGCampaignPopup, setShowSGCampaignPopup] = useState(isSURCampaign);

  const articlePath = isSingtel ? "singtel_articles" : "celcom_articles";
  const articleLanguagePath = isSingtel
    ? "prepare-your-phone-for-a-screen-repair"
    : language === LANGUAGE.BAHASA
      ? "prepare-your-phone-for-screen-repair-bs/"
      : "prepare-your-phone-for-screen-repair/";

  // const PrepareLink = `${Config.WPAPIServer.baseUrl}/singtel_articles/prepare-your-phone-for-a-screen-repair/`;
  const PrepareLink = `https://kb-prod.sea.soluto.asurion.com/${articlePath}/${articleLanguagePath}`;

  const Banner = () => {
    const [isDesktop, setIsDesktop] = useState(
      window.matchMedia("(min-width: 1024px)").matches
    );

    useEffect(() => {
      window
        .matchMedia("(min-width: 1024px)")
        .addEventListener("change", (e) => setIsDesktop(e.matches));
    }, []);

    const arrowStyles = {
      position: "absolute",
      zIndex: 2,
      top: "calc(50% - 15px)",
      width: 48,
      height: 48,
      cursor: "pointer",
    };

    const indicatorStyles = {
      background: "#6E767D",
      width: 8,
      height: 8,
      display: "inline-block",
      margin: "-8px 8px",
      borderRadius: "50%",
    };

    return (
      <>
        {isSingtel && (
          <div>
            {isBattRepCampaign
              ? <>
                <Carousel
                  infiniteLoop={true}
                  showThumbs={false}
                  showStatus={false}
                  showArrows={isDesktop ? true : false}
                  selectedItem={isBattRepCampaign ? 3 : 0}
                  renderIndicator={(onClickHandler, isSelected, index, label) => {
                    if (isSelected) {
                      return (
                        <li
                          style={{ ...indicatorStyles, background: "#000" }}
                          aria-label={`Selected: ${label} ${index + 1}`}
                          title={`Selected: ${label} ${index + 1}`}
                        />
                      );
                    }
                    return (
                      <li
                        style={indicatorStyles}
                        onClick={onClickHandler}
                        onKeyDown={onClickHandler}
                        value={index}
                        key={index}
                        role="button"
                        tabIndex={0}
                        title={`${label} ${index + 1}`}
                        aria-label={`${label} ${index + 1}`}
                      />
                    );
                  }}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        style={{ ...arrowStyles, left: 15 }}
                      >
                        <img src={rightNav} alt="" />
                      </button>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        style={{ ...arrowStyles, right: 15 }}
                      >
                        <img src={leftNav} alt="" />
                      </button>
                    )
                  }
                >
                  <div className="flex flex-row w-full h-full bg-srgray-septenary">
                    <div
                      className="hidden w-1/2 xl:block"
                    // style={{ height: "430px" }}
                    >
                      <img
                        className="hidden object-cover h-full p-0 xl:block"
                        src={BannerImage1}
                        alt=""
                        style={{ width: "auto" }}
                      />
                    </div>
                    <div className="pb-3 mx-auto text-black bg-transparent md:p-10 xl:p-18 s1600:p-32 xl:w-1/2 flex flex-col justify-center">
                      <div className="pt-8 text-5xl xl:text-6xl xl:pt-0 xl:pr-4 xl:flex">
                        <Translate i18nKey="HomeBody.Banner.Title1">
                          <TextMedium>Family Plan Unlimited Premium</TextMedium>
                        </Translate>
                      </div>
                      <div className="px-8 mt-6 text-3xl text-left xl:my-8 xl:px-0 xl:pr-12">
                        <Translate i18nKey="HomeBody.Banner.Label1">
                          Your phone screen is ruined. Your day isn’t.
                          <br />
                          Get fast, convenient screen repair at an affordable price.
                          <br />
                          <br />
                          The phone you love, made good as new.
                        </Translate>
                      </div>
                      <BaseButton
                        text={t("HomeBody.Banner.Button1")}
                        className="w-60% xl:w-48 pl-6 pr-6 mx-auto xl:mx-0 border-white border xl:border-none mt-8 xl:mt-12"
                        focused
                        onClick={() =>
                          window.open(
                            "https://www.singtel.com/personal/products-services/mobile/add-ons/swop"
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="flex flex-row w-full h-full bg-srgray-septenary">
                    <div
                      className="hidden w-1/2 xl:block"
                    // style={{ height: "430px" }}
                    >
                      <img
                        className="hidden object-cover h-full p-0 xl:block"
                        src={BannerImage2New}
                        alt=""
                        style={{ width: "auto" }}
                      />
                    </div>
                    <div className="pb-3 mx-auto text-black bg-transparent md:p-10 xl:py-18 s1600:p-32 xl:w-1/2 flex flex-col justify-center">
                      <div className="justify-start pt-8 text-5xl xl:text-6xl xl:pt-0 xl:pr-4 xl:flex">
                        <Translate i18nKey="HomeBody.Banner.Title2">
                          <TextMedium>Family Plan Unlimited Premium</TextMedium>
                        </Translate>
                      </div>
                      <div className="px-8 mt-6 text-3xl text-left xl:my-8 xl:px-0 xl:pr-12">
                        <Translate i18nKey="HomeBody.Banner.Label2">
                          Your phone screen is ruined. Your day isn’t.
                          <br />
                          Get fast, convenient screen repair at an affordable price.
                          <br />
                          <br />
                          The phone you love, made good as new.
                        </Translate>
                      </div>
                      <BaseButton
                        text={t("HomeBody.Banner.Button2")}
                        className="w-60% xl:w-48 pl-6 pr-6 mx-auto xl:mx-0 border-white border xl:border-none mt-8 xl:mt-12"
                        focused
                        // onClick={onGetStarted}
                        onClick={() => { myAsurionSingtelUrl ? window.location = myAsurionSingtelUrl : onGetStarted() }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row w-full h-full bg-srgray-septenary">
                    <div
                      className="hidden w-1/2 xl:block"
                    // style={{ height: "430px" }}
                    >
                      <img
                        className="hidden object-cover h-full p-0 xl:block"
                        src={BannerImage3New}
                        alt=""
                        style={{ width: "auto" }}
                      />
                    </div>
                    <div className="pb-3 mx-auto text-black bg-transparent md:p-10 xl:p-18 s1600:p-32 xl:w-1/2 flex flex-col justify-center">
                      <div className="justify-start pt-8 text-5xl xl:text-6xl xl:pt-0 xl:pr-4 xl:flex">
                        <Translate i18nKey="HomeBody.Banner.Title3">
                          <TextMedium>Free 4-hour Delivery</TextMedium>
                        </Translate>
                      </div>
                      <div className="px-8 mt-6 text-3xl text-left xl:my-8 xl:px-0 xl:pr-12">
                        <Translate i18nKey="HomeBody.Banner.Label3">
                          Our complimentary delivery service picks up and delivers
                          your repaired phone right to your door.
                          <br />
                          <br />
                          This way, you can spend more time doing what you like.
                        </Translate>
                      </div>
                      <BaseButton
                        text={t("HomeBody.Banner.Button3")}
                        className="w-60% xl:w-48 pl-6 pr-6 mx-auto xl:mx-0 border-white border xl:border-none mt-8 xl:mt-12"
                        focused
                        onClick={() => setShowDeliveryTimeTablePopup(true)}
                      />
                    </div>
                  </div>
                  {isBattRepCampaign &&
                    <div className="flex flex-row w-full h-full bg-srgray-septenary">
                      <div
                        className="hidden w-1/2 xl:block"
                      // style={{ height: "430px" }}
                      >
                        <img
                          className="hidden object-cover h-full p-0 xl:block"
                          src={BannerImageStarterBatt}
                          alt=""
                          style={{ width: "auto", height: "480px" }}
                        />
                      </div>
                      <div className="pb-3 mx-auto text-black bg-transparent md:p-10 xl:py-18 s1600:p-32 xl:w-1/2 flex flex-col justify-center">
                        <div className="justify-start pt-8 text-5xl xl:text-6xl xl:pt-0 xl:pr-4 xl:flex">
                          Battery barely lasts a day?
                        </div>
                        <div className="px-8 mt-6 text-3xl text-left xl:my-8 xl:px-0 xl:pr-12">
                          Get a battery replacement at <strong>only $79!</strong> This is a separate service limited to new subscribers of MobileSwop Unlimited Premium and MobileSwop Family, from<br /><strong>Aug 1 - Oct 31 2024</strong>.
                        </div>
                        <BaseButton
                          text={t("HomeBody.Banner.Button2")}
                          className="w-60% xl:w-48 pl-6 pr-6 mx-auto xl:mx-0 border-white border xl:border-none mt-8 xl:mt-12"
                          focused
                          // onClick={onGetStarted}
                          onClick={() => { myAsurionSingtelUrl ? window.location = myAsurionSingtelUrl : onGetStarted() }}
                        />
                      </div>
                    </div>
                  }
                </Carousel>
              </>
              : <>
                <Carousel
                  infiniteLoop={true}
                  showThumbs={false}
                  showStatus={false}
                  showArrows={isDesktop ? true : false}
                  selectedItem={isBattRepCampaign ? 3 : 0}
                  renderIndicator={(onClickHandler, isSelected, index, label) => {
                    if (isSelected) {
                      return (
                        <li
                          style={{ ...indicatorStyles, background: "#000" }}
                          aria-label={`Selected: ${label} ${index + 1}`}
                          title={`Selected: ${label} ${index + 1}`}
                        />
                      );
                    }
                    return (
                      <li
                        style={indicatorStyles}
                        onClick={onClickHandler}
                        onKeyDown={onClickHandler}
                        value={index}
                        key={index}
                        role="button"
                        tabIndex={0}
                        title={`${label} ${index + 1}`}
                        aria-label={`${label} ${index + 1}`}
                      />
                    );
                  }}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        style={{ ...arrowStyles, left: 15 }}
                      >
                        <img src={rightNav} alt="" />
                      </button>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        style={{ ...arrowStyles, right: 15 }}
                      >
                        <img src={leftNav} alt="" />
                      </button>
                    )
                  }
                >
                  <div className="flex flex-row w-full h-full bg-srgray-septenary">
                    <div
                      className="hidden w-1/2 xl:block"
                    // style={{ height: "430px" }}
                    >
                      <img
                        className="hidden object-cover h-full p-0 xl:block"
                        src={BannerImage1}
                        alt=""
                        style={{ width: "auto" }}
                      />
                    </div>
                    <div className="pb-3 mx-auto text-black bg-transparent md:p-10 xl:p-18 s1600:p-32 xl:w-1/2 flex flex-col justify-center">
                      <div className="pt-8 text-5xl xl:text-6xl xl:pt-0 xl:pr-4 xl:flex">
                        <Translate i18nKey="HomeBody.Banner.Title1">
                          <TextMedium>Family Plan Unlimited Premium</TextMedium>
                        </Translate>
                      </div>
                      <div className="px-8 mt-6 text-3xl text-left xl:my-8 xl:px-0 xl:pr-12">
                        <Translate i18nKey="HomeBody.Banner.Label1">
                          Your phone screen is ruined. Your day isn’t.
                          <br />
                          Get fast, convenient screen repair at an affordable price.
                          <br />
                          <br />
                          The phone you love, made good as new.
                        </Translate>
                      </div>
                      <BaseButton
                        text={t("HomeBody.Banner.Button1")}
                        className="w-60% xl:w-48 pl-6 pr-6 mx-auto xl:mx-0 border-white border xl:border-none mt-8 xl:mt-12"
                        focused
                        onClick={() =>
                          window.open(
                            "https://www.singtel.com/personal/products-services/mobile/add-ons/swop"
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="flex flex-row w-full h-full bg-srgray-septenary">
                    <div
                      className="hidden w-1/2 xl:block"
                    // style={{ height: "430px" }}
                    >
                      <img
                        className="hidden object-cover h-full p-0 xl:block"
                        src={BannerImage2New}
                        alt=""
                        style={{ width: "auto" }}
                      />
                    </div>
                    <div className="pb-3 mx-auto text-black bg-transparent md:p-10 xl:py-18 s1600:p-32 xl:w-1/2 flex flex-col justify-center">
                      <div className="justify-start pt-8 text-5xl xl:text-6xl xl:pt-0 xl:pr-4 xl:flex">
                        <Translate i18nKey="HomeBody.Banner.Title2">
                          <TextMedium>Family Plan Unlimited Premium</TextMedium>
                        </Translate>
                      </div>
                      <div className="px-8 mt-6 text-3xl text-left xl:my-8 xl:px-0 xl:pr-12">
                        <Translate i18nKey="HomeBody.Banner.Label2">
                          Your phone screen is ruined. Your day isn’t.
                          <br />
                          Get fast, convenient screen repair at an affordable price.
                          <br />
                          <br />
                          The phone you love, made good as new.
                        </Translate>
                      </div>
                      <BaseButton
                        text={t("HomeBody.Banner.Button2")}
                        className="w-60% xl:w-48 pl-6 pr-6 mx-auto xl:mx-0 border-white border xl:border-none mt-8 xl:mt-12"
                        focused
                        // onClick={onGetStarted}
                        onClick={() => { myAsurionSingtelUrl ? window.location = myAsurionSingtelUrl : onGetStarted() }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row w-full h-full bg-srgray-septenary">
                    <div
                      className="hidden w-1/2 xl:block"
                    // style={{ height: "430px" }}
                    >
                      <img
                        className="hidden object-cover h-full p-0 xl:block"
                        src={BannerImage3New}
                        alt=""
                        style={{ width: "auto" }}
                      />
                    </div>
                    <div className="pb-3 mx-auto text-black bg-transparent md:p-10 xl:p-18 s1600:p-32 xl:w-1/2 flex flex-col justify-center">
                      <div className="justify-start pt-8 text-5xl xl:text-6xl xl:pt-0 xl:pr-4 xl:flex">
                        <Translate i18nKey="HomeBody.Banner.Title3">
                          <TextMedium>Free 4-hour Delivery</TextMedium>
                        </Translate>
                      </div>
                      <div className="px-8 mt-6 text-3xl text-left xl:my-8 xl:px-0 xl:pr-12">
                        <Translate i18nKey="HomeBody.Banner.Label3">
                          Our complimentary delivery service picks up and delivers
                          your repaired phone right to your door.
                          <br />
                          <br />
                          This way, you can spend more time doing what you like.
                        </Translate>
                      </div>
                      <BaseButton
                        text={t("HomeBody.Banner.Button3")}
                        className="w-60% xl:w-48 pl-6 pr-6 mx-auto xl:mx-0 border-white border xl:border-none mt-8 xl:mt-12"
                        focused
                        onClick={() => setShowDeliveryTimeTablePopup(true)}
                      />
                    </div>
                  </div>
                </Carousel>
              </>
            }
          </div>
        )}
        {!isSingtel && (
          <article className={`relative h-full ${isSingtel ? "bg-srgray-septenary" : "bg-v2Linear xl:bg-v2ImageLinear"}`}
          style={{backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}
          >
            <div className="flex" style={isSingtel ? {} : { height: "500px" }}>
              <div className="hidden top-0 bottom-0 right-0 z-0 h-full mx-auto xl:block xl:absolute">
                <img
                  className="hidden object-cover h-full p-0 xl:block"
                  src={isSingtel ? BannerImage : BannerImageCelcom}
                  alt=""
                />
              </div>
              <div className="relative pb-3 mx-auto text-center text-white bg-transparent md:p-10 xl:p-18 s1600:p-32 xl:w-4/5 xl:text-left self-center">
                <div className="px-8 pt-8 text-5xl xl:text-6xl xl:px-0 xl:pt-0">
                  <Translate i18nKey="HomeBody.Banner.Title">
                    <TextMedium>Get your cracked screen repaired.</TextMedium>
                  </Translate>
                </div>
                <div className="px-8 mt-6 text-3xl text-left xl:my-8 xl:px-0">
                  <Translate i18nKey="HomeBody.Banner.Label">
                    Your phone screen is ruined. Your day isn’t.
                    <br />
                    Get fast, convenient screen repair at an affordable price.
                    <br />
                    <br />
                    The phone you love, made good as new.
                  </Translate>
                </div>
                <div>
                  <BaseButton
                    text={t("HomeBody.Banner.Button")}
                    className="celcomdigi w-60% xl:w-48 pl-6 pr-6 mx-auto xl:mx-0 border-none mt-8 xl:mt-0"
                    focused
                    // onClick={() => {isSingtel ? onGetStarted() : myAsurionUrl ? window.location = myAsurionUrl : onGetStarted()}}
                    onClick={() => {isSingtel 
                      ? myAsurionSingtelUrl ? window.location = myAsurionSingtelUrl : onGetStarted() 
                      : myAsurionUrl ? window.location = myAsurionUrl : onGetStarted()}}
                  />
                  {!isSingtel && !myAsurionUrl && <div>
                    <BaseLink
                      className="text-white text-2xl font-DINPro-Medium"
                      // onClick={() => window.open(`${t("SRTutorialURL")}`, "service request tutorial")}
                      onClick={() => setShowTutorialPopup(true)}
                    >
                      <Translate i18nKey="SRTutorialButtonLabel">
                        <TextMedium>Show Me Tutorial</TextMedium>
                      </Translate>
                    </BaseLink>
                  </div>}
                </div>
              </div>
            </div>
          </article>
        )}
      </>
    );
  };

  const Services = () => (
    <article className={`w-full p-8 bg-white s1600:p-14 ${isSingtel ? "pb-0" : ""}`}>
      <div className="w-full text-5xl text-center">
        <div className="mt-8">
          <Translate i18nKey="HomeBody.ArticleOne.Title">
            <TextMedium>
              Here’s how to get your Screen Repair service
            </TextMedium>
          </Translate>
        </div>

        {isSingtel
          ? (
            <>
              <div className="flex flex-row flex-wrap justify-center text-black xl:flex-no-wrap">
                <div className="flex flex-row flex-wrap justify-center xl:flex-no-wrap">
                  <div className="flex flex-col my-4 ml-4 text-left">
                    <img
                      className="mt-4 mb-4"
                      src={isSingtel ? IconPhone : CrackedScreenHandIcon}
                      alt=""
                      style={{ height: "80px" }}
                    />
                    <div className="ml-2 xl:ml-8 mr-2 w-20r text-center" style={{ width: "25rem" }}>
                      <div className="my-4 text-2xl" style={{ fontSize: "24px" }}>
                        <Translate i18nKey="HomeBody.ArticleOne.Contents.1.Title">
                          <TextLight>Assess screen repair eligibility</TextLight>
                        </Translate>
                      </div>
                      <div className="text-default leading-tight" style={{ fontSize: "18px" }}>
                        <Translate i18nKey="HomeBody.ArticleOne.Contents.1.Information">
                          <TextLight>
                            Screen Repair is only eligible for Apple and Samsung
                            smartphones. It is not applicable for Samsung Galaxy Fold
                            series, tablets and smartwatches.
                          </TextLight>
                        </Translate>
                        {isSingtel && (
                          <div className="mt-2">
                            <BaseLink
                              className=" text-srgreen-secondary text-default underline"
                              style={{ color: "black" }}
                              onClick={() => setShowProgramsPopup(true)}
                            >
                              <Translate i18nKey="HomeBody.ArticleOne.Contents.1.Link">
                                <TextMedium>Find out more</TextMedium>
                              </Translate>
                            </BaseLink>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <ArrowStep className="hidden s1366:block w-14" /> */}
                  <div className="flex flex-col my-4 ml-4 text-left">
                    <img
                      className="mt-4 mb-4"
                      src={isSingtel ? IconPreparePhone : CrackedScreenIcon}
                      alt=""
                      style={{ height: "80px" }}
                    />
                    <div className="ml-2 xl:ml-8 mr-2 w-20r text-center" style={{ width: "25rem" }}>
                      <div className="my-4 text-2xl" style={{ fontSize: "24px" }}>
                        <Translate i18nKey="HomeBody.ArticleOne.Contents.2.Title">
                          <TextLight>Prepare your phone</TextLight>
                        </Translate>
                      </div>
                      <div className="text-default leading-tight" style={{ fontSize: "18px" }}>
                        <Translate i18nKey="HomeBody.ArticleOne.Contents.2.Information">
                          <TextLight>
                            You will need to turn off activation lock, perform data
                            wipe and factory reset. Before this, you may wish to
                            backup your data.
                          </TextLight>
                        </Translate>
                        <div className={`${isSingtel ? "mt-2" : "mt-0"}`}>
                          <BaseLink
                            className="text-srgreen-secondary text-default underline"
                            style={{ color: "black" }}
                            onClick={() => setShowPopup(true)}
                          >
                            <Translate i18nKey="HomeBody.ArticleOne.Contents.2.Link">
                              <TextMedium>How to Guide</TextMedium>
                            </Translate>
                          </BaseLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row flex-wrap justify-center xl:flex-no-wrap">
                  {/* <ArrowStep className="hidden s1366:block w-14" /> */}
                  <div className="flex flex-col my-4 ml-4 text-left">
                    <img className="mt-4 mb-4" style={{ height: "80px" }} src={UserIcon} alt="" />
                    <div className="ml-2 xl:ml-8 mr-2 w-20r text-center" style={{ width: "25rem" }}>
                      <div className="my-4 text-2xl" style={{ fontSize: "24px" }}>
                        <Translate i18nKey="HomeBody.ArticleOne.Contents.3.Title">
                          <TextLight>Schedule for pickup</TextLight>
                        </Translate>
                      </div>
                      <div className="text-default leading-tight" style={{ fontSize: "18px" }}>
                        <Translate i18nKey="HomeBody.ArticleOne.Contents.3.Information">
                          <TextLight>
                            Your phone will be sent to authorized service centre for
                            physical assessment of Screen Repair suitability
                          </TextLight>
                        </Translate>
                        {isSingtel && (
                          <div className="mt-2">
                            <BaseLink
                              className="text-srgreen-secondary text-default underline"
                              style={{ color: "black" }}
                              // onClick={onGetStarted}
                              onClick={() => {myAsurionSingtelUrl ? window.location = myAsurionSingtelUrl : onGetStarted()}}
                            >
                              <Translate i18nKey="HomeBody.ArticleOne.Contents.3.Link">
                                <TextMedium>Get started</TextMedium>
                              </Translate>
                            </BaseLink>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <ArrowStep className="hidden s1366:block w-14" /> */}
                  <div className="flex flex-col my-4 ml-4 text-left">
                    <img
                      className="mt-4 mb-4"
                      src={isSingtel ? IconReturnRepairedPhone : RepairedScreenIcon}
                      alt=""
                      style={{ height: "80px" }}
                    />
                    <div className="ml-2 xl:ml-8 mr-2 w-20r text-center" style={{ width: "25rem" }}>
                      <div className="my-4 text-2xl" style={{ fontSize: "24px" }}>
                        <Translate i18nKey="HomeBody.ArticleOne.Contents.4.Title">
                          <TextLight>Return repaired phone</TextLight>
                        </Translate>
                      </div>
                      <div className="text-default leading-tight" style={{ fontSize: "18px" }}>
                        <Translate i18nKey="HomeBody.ArticleOne.Contents.4.Information">
                          <TextLight>
                            Screen Repair will take 2 business days and repair fee
                            will be charged upon completion.
                          </TextLight>
                        </Translate>
                        {isSingtel && (
                          <div className="mt-2">
                            <BaseLink
                              className="text-srgreen-secondary text-default underline"
                              style={{ color: "black" }}
                              onClick={() => setShowDeliveryTimeTablePopup(true)}
                            >
                              <Translate i18nKey="HomeBody.ArticleOne.Contents.4.Link">
                                <TextMedium>Learn about the Schedule</TextMedium>
                              </Translate>
                            </BaseLink>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
          : (
            <>
              <div className="flex flex-row flex-wrap justify-center text-black xl:flex-no-wrap pb-6 gap-6">
                <div className="flex flex-row flex-wrap justify-center xl:flex-no-wrap gap-6">
                  <div className="flex flex-col my-4 xl:ml-4 text-center">
                    <img
                      className="mt-4 mb-5 mx-auto" 
                      src={isSingtel ? IconPhone : CrackedScreenHandIcon}
                      alt=""
                      style={{height: "75px"}}
                    />
                    <div className="ml-8x mr-2x w-28">
                      <div className="my-4 text-3xl text-center">
                        <Translate i18nKey="HomeBody.ArticleOne.Contents.1.Title">
                          <TextMedium>Assess screen repair eligibility</TextMedium>
                        </Translate>
                      </div>
                      <div className="text-2xl text-left">
                        <Translate i18nKey="HomeBody.ArticleOne.Contents.1.Information">
                          <TextLight>
                            Screen Repair is only eligible for Apple and Samsung
                            smartphones. It is not applicable for Samsung Galaxy Fold
                            series, tablets and smartwatches.
                          </TextLight>
                        </Translate>
                        {isSingtel && (
                          <div className="mt-2">
                            <BaseLink
                              className=" text-srgreen-secondary"
                              onClick={() => setShowProgramsPopup(true)}
                            >
                              <Translate i18nKey="HomeBody.ArticleOne.Contents.1.Link">
                                <TextMedium>Find out more</TextMedium>
                              </Translate>
                            </BaseLink>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <ArrowStep className="hidden s1366:block w-14" /> */}
                  <div className="flex flex-col my-4 xl:ml-4 text-center">
                    <img
                      className="mt-4 mb-5 mx-auto"
                      src={isSingtel ? IconPreparePhone : CrackedScreenIcon}
                      alt=""
                      style={{height: "75px"}}
                    />
                    <div className="ml-8x mr-2x w-28">
                      <div className="my-4 text-3xl text-center">
                        <Translate i18nKey="HomeBody.ArticleOne.Contents.2.Title">
                          <TextMedium>Prepare your phone</TextMedium>
                        </Translate>
                      </div>
                      <div className="text-2xl text-left">
                        <Translate i18nKey="HomeBody.ArticleOne.Contents.2.Information">
                          <TextLight>
                            You will need to turn off activation lock, perform data
                            wipe and factory reset. Before this, you may wish to
                            backup your data.
                          </TextLight>
                        </Translate>
                        <div className={`${isSingtel ? "mt-2" : "mt-0"}`}>
                          <BaseLink
                            className="text-celcomprimary-DEFAULT"
                            onClick={() => setShowPopup(true)}
                          >
                            <Translate i18nKey="HomeBody.ArticleOne.Contents.2.Link">
                              <TextMedium>How to Guide</TextMedium>
                            </Translate>
                          </BaseLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row flex-wrap justify-center xl:flex-no-wrap gap-6">
                  {/* <ArrowStep className="hidden s1366:block w-14" /> */}
                  <div className="flex flex-col my-4 xl:ml-4 text-center">
                    <img
                      className="w-32 mt-4 mb-5 mx-auto"
                      src={UserIcon}
                      alt=""
                      style={{ height: "75px" }} />
                    <div className="ml-8x mr-2x w-28">
                      <div className="my-4 text-3xl text-center">
                        <Translate i18nKey="HomeBody.ArticleOne.Contents.3.Title">
                          <TextMedium>Schedule for pickup</TextMedium>
                        </Translate>
                      </div>
                      <div className="text-2xl text-left">
                        <Translate i18nKey="HomeBody.ArticleOne.Contents.3.Information">
                          <TextLight>
                            Your phone will be sent to authorized service centre for
                            physical assessment of Screen Repair suitability
                          </TextLight>
                        </Translate>
                        {isSingtel && (
                          <div className="mt-2">
                            <BaseLink
                              className="text-srgreen-secondary"
                              // onClick={onGetStarted}
                              onClick={() => {myAsurionSingtelUrl ? window.location = myAsurionSingtelUrl : onGetStarted()}}
                            >
                              <Translate i18nKey="HomeBody.ArticleOne.Contents.3.Link">
                                <TextMedium>Get started</TextMedium>
                              </Translate>
                            </BaseLink>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <ArrowStep className="hidden s1366:block w-14" /> */}
                  <div className="flex flex-col my-4 xl:ml-4 text-center">
                    <img
                      className="mt-4 mb-5 mx-auto"
                      src={isSingtel ? IconReturnRepairedPhone : RepairedScreenIcon}
                      alt=""
                      style={{height: "75px"}}
                    />
                    <div className="ml-8x mr-2x w-28">
                      <div className="my-4 text-3xl text-center">
                        <Translate i18nKey="HomeBody.ArticleOne.Contents.4.Title">
                          <TextMedium>Return repaired phone</TextMedium>
                        </Translate>
                      </div>
                      <div className="text-2xl text-left">
                        <Translate i18nKey="HomeBody.ArticleOne.Contents.4.Information">
                          <TextLight>
                            Screen Repair will take 2 business days and repair fee
                            will be charged upon completion.
                          </TextLight>
                        </Translate>
                        {isSingtel && (
                          <div className="mt-2">
                            <BaseLink
                              className="text-srgreen-secondary"
                              onClick={() => setShowDeliveryTimeTablePopup(true)}
                            >
                              <Translate i18nKey="HomeBody.ArticleOne.Contents.4.Link">
                                <TextMedium>Learn about the Schedule</TextMedium>
                              </Translate>
                            </BaseLink>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }


      </div>
    </article>
  );

  const RepairService = () => (
    <article className="text-white bg-white">
      {isSingtel && (
        <>
          <div className="hidden xl:block">
            <div
              className="flex flex-row justify-between mt-20"
              // style={{
              //   background:
              //     "linear-gradient(90deg, #F3F6F8 28.12%, #FFFFFF 100%)",
              // }}
              style={{ background: "#ECDCF9" }}
            >
              <div className="w-2/5 my-auto overflow-hidden">
                <img
                  src={DamagedPhoneBannerNew}
                  className=""
                  alt=""
                  style={{ float: "left", marginBottom: "-5px", padding: "0", background: "#ECDCF9" }}
                />
              </div>

              <div className="w-7/12 pt-24x pr-20 text-2xl text-black self-center" style={{ background: "#ECDCF9" }}>
                <div className={`text-5xl leading-tight`}>
                  <Translate i18nKey="HomeBody.ArticleTwo.Heading">
                    <TextLight>
                      Get your cracked screen repaired at just $99
                    </TextLight>
                  </Translate>
                </div>
                <div className={`mt-12 mb-10 text-4xl`}>
                  <Translate i18nKey="HomeBody.ArticleTwo.SubHeading">
                    <TextLight>What is a Screen Repair Service?</TextLight>
                  </Translate>
                </div>
                <div className="mt-2 text-3xl">
                  <Translate i18nKey="HomeBody.ArticleTwo.Information">
                    Screen Repair provides hassle-free Screen Repair service for
                    $99 and you can stay in the comfort of your home while we
                    pick up your damaged device for repair and return it to you
                    once it is done. Each screen repair service comes with a
                    3-month warranty for the screen against manufacturer
                    malfunctions from the date the phone is returned to you.
                    <br />
                    <br />
                    Say goodbye to cracked screens with Screen Repair.
                  </Translate>
                </div>
                <div className="mt-10 text-2xl">
                  <Translate i18nKey="HomeBody.ArticleTwo.Footnote">
                    Do note that manufacturing malfunctions or defects does not
                    include battery degradation
                  </Translate>
                </div>
              </div>
            </div>
          </div>
          <img
            src={DamagedPhoneBannerNew}
            className="mx-auto mt-12 sm:hidden"
            alt=""
            style={{ marginBottom: "-60px", padding: "0", background: "#ECDCF9" }}
          />
        </>
      )}
      <div className={`xl:${isSingtel ? "hidden" : "block"} ${isSingtel ? "overflow-hidden" :"overflow-visible relative"}`}>
        <div
          className={
            `${isSingtel ? "h-24" : "h-20x -mb-32x mt-16x absolute"} overflow-visible mx-auto w-4/5`
          }
          style={isSingtel ? {} : {height: "100%", left: "13.5%", top: "-35px"}}
        >
          <div 
          className={`${isCelcomCampaignInactive ? "w-auto relative" : "w-40% relative"}`}
          style={isSingtel ? {} : {height: "calc(100% + 40px)"}}
          >
            <img src={DamagedPhoneImage} className="hidden xl:block h-full" alt="" />
            {isCelcomCampaignInactive ? <></> :
              <div
                className="hidden xl:flex flex-col absolute bg-srgreen-secondaryx bg-celcomprimary-DEFAULT rounded rounded-full text-center text-white"
                style={{
                  width: '190px',
                  height: '190px',
                  top: '0',
                  right: '0'
                }}>
                <Translate i18nKey="HomeBody.Promo.Circle">
                  <span className="inline-block mx-auto my-auto leading-tight" style={{ width: '50%', fontSize: '20px' }}>
                    Get up to 66% off* on repair fee**
                  </span>
                </Translate>
              </div>
            }
          </div>
        </div>
        <div
          className={`${isSingtel ? "bg-white" : "bg-celcomsrgray-lightgrey"
            } s1600:min-h-41r`}
          style={{ background: isSingtel ? "#ECDCF9" : undefined }}
        >
          <div className={`pt-8 xl:p-12 mx-auto w-4/5 flex flex-row-reverse ${isSingtel ? "s1600:w-4/5 xl:w-90%" : "xl:w-4/5"}`}>
            <div
              className={`${isSingtel ? "xl:w-60%" : isCelcomCampaignInactive ? "xl:w-65%" : "xl:w-55%"} mx-auto xl:mx-0 my-auto text-center xl:text-left ${isSingtel ? "text-black" : "text-black"
                } ${!isSingtel && !isCelcomCampaignInactive ? "xl:pl-12" : ""}`}
              style={{ textAlign: isSingtel ? "start" : undefined, paddingLeftx: !isSingtel && !isCelcomCampaignInactive ? "40px" : undefined }}
            >
              <div className={`text-5xl leading-tight`}>
                <Translate i18nKey="HomeBody.ArticleTwo.Heading">
                  <TextLight>
                    Get your cracked screen repaired at just $99
                  </TextLight>
                </Translate>
              </div>
              <div className={`mt-8 mb-2 ${isSingtel ? "text-4xl" : "text-3xl"} font-bold`}>
                <Translate i18nKey="HomeBody.ArticleTwo.SubHeading">
                  <TextLight>What is a Screen Repair Service?</TextLight>
                </Translate>
              </div>
              <div className="my-2 text-2xl">
                <Translate i18nKey="HomeBody.ArticleTwo.Information">
                  Screen Repair provides hassle-free Screen Repair service for
                  $99 and you can stay in the comfort of your home while we pick
                  up your damaged device for repair and return it to you once it
                  is done. Each screen repair service comes with a 3-month
                  warranty for the screen against manufacturer malfunctions from
                  the date the phone is returned to you.
                  <br />
                  <br />
                  Say goodbye to cracked screens with Screen Repair.
                </Translate>
              </div>
              {!isSingtel && !isCelcomCampaignInactive && (
                <div className="inline-block mt-10 mb-4 p-6x text-blackx bg-gray-500x text-4xl leading-tight" style={{ fontSize: '18pxx' }}>
                  <Translate i18nKey="HomeBody.Promo.BlueSection">
                    <strong>*Discounts apply from 1 August to 31 December 2023 only</strong>
                    <span>**The actual discount varies according to the Tier</span>
                    <span>The discounted fee will appear at the payment selection page</span>
                  </Translate>
                </div>
              )}
              {isSingtel && (
                <div className="mt-2 text-xl">
                  <Translate i18nKey="HomeBody.ArticleTwo.Footnote">
                    Do note that manufacturing malfunctions or defects does not
                    include battery degradation
                  </Translate>
                </div>
              )}
              {/* <div>
                <BaseButton
                  text="Find out how"
                  className="w-56 pl-6 pr-2 mx-auto border border-white border-solid xl:mx-0"
                  focused
                  onClick={() => setShowPopup(true)}
                />
              </div> */}
            </div>
          </div>
          <div className={`xl:hidden ${!isSingtel ? "flex relative mt-6" : "w-full"}`}>
            {!isSingtel && (
              <div className="mx-auto relative">
                {isCelcomCampaignInactive
                  ? <img src={DamagedPhoneImageMobile} className="inline-block p-0 h-24r" style={{ marginLeft: '55px' }} />
                  : <img src={DamagedPhoneImageMobile} className="inline-block p-0 h-24r" style={{ marginRight: '110px' }} />
                }
                {isCelcomCampaignInactive
                  ? <></>
                  : <div className="inline-block absolute top-0 right-0">
                    <div
                      className="flex flex-col bg-srgreen-secondaryx bg-celcomprimary-DEFAULT rounded rounded-full text-center text-white m-0"
                      style={{
                        width: '170px',
                        height: '170px',
                      }}>
                      <Translate i18nKey="HomeBody.Promo.Circle">
                        <span className="inline-block mx-auto my-auto leading-tight" style={{ width: '50%', fontSize: '18px' }}>
                          Get up to 66% off* on repair fee**
                        </span>
                      </Translate>
                    </div>
                  </div>
                }
              </div>
            )}
          </div>
        </div>
      </div>
      {!isSingtel && (
        <div className="w-full px-8 xl:w-4/5 xl:px-0 xl:mx-auto my-12 text-5xl text-center text-black">
          <div className="flex justify-center xl:px-8 xl:w-full mx-auto font-bold">
            {isCelcomCampaignInactive
              ? <Translate i18nKey="HomeBody.ArticleTwo.Table.Header">
                <TextLight></TextLight>
              </Translate>
              : <Translate i18nKey="HomeBody.Promo.Table.Header">
                <TextLight></TextLight>
              </Translate>
            }
          </div>
          <div className="flex justify-center xl:px-2 xl:w-full mx-auto my-4">
            <div className="hidden xl:block w-full">
              {isCelcomCampaignInactive
                ? <>
                  <BaseTable>
                    <BaseTR key={"1"}>
                      <BaseTD className="text-white bg-black whiteborder">
                        {t("HomeBody.ArticleTwo.Table.Th1")}
                      </BaseTD>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {"0-500"}
                      </BaseTD>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {"501-1000"}
                      </BaseTD>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {"1001-1500"}
                      </BaseTD>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {"1501-3000"}
                      </BaseTD>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {"3001-6000"}
                      </BaseTD>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {">6000"}
                      </BaseTD>
                    </BaseTR>
                    <BaseTR key={"2"}>
                      <BaseTD className="text-white bg-celcomprimary-dark whiteborder">
                        {t("HomeBody.ArticleTwo.Table.Th2")}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-throughx whiteborder">
                        {"RM 30"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-throughx whiteborder">
                        {"RM 60"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-throughx whiteborder">
                        {"RM 110"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-throughx whiteborder">
                        {"RM 220"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-throughx whiteborder">
                        {"RM 350"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-throughx whiteborder">
                        {"RM 500"}
                      </BaseTD>
                    </BaseTR>
                  </BaseTable>
                </>
                : <>
                  <BaseTable>
                    <BaseTR key={"1"}>
                      <BaseTD className="text-white bg-black whiteborder">
                        {t("HomeBody.ArticleTwo.Table.Th1")}
                      </BaseTD>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {"0-500"}
                      </BaseTD>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {"501-1000"}
                      </BaseTD>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {"1001-1500"}
                      </BaseTD>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {"1501-3000"}
                      </BaseTD>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {"3001-6000"}
                      </BaseTD>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {">6000"}
                      </BaseTD>
                    </BaseTR>
                    <BaseTR key={"2"}>
                      <BaseTD className="text-white bg-celcomprimary-dark whiteborder">
                        {t("HomeBody.ArticleTwo.Table.Th2")}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-through whiteborder">
                        {"RM 30"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-through whiteborder">
                        {"RM 60"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-through whiteborder">
                        {"RM 110"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-through whiteborder">
                        {"RM 220"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-through whiteborder">
                        {"RM 350"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-through whiteborder">
                        {"RM 500"}
                      </BaseTD>
                    </BaseTR>
                    <BaseTR key={"3"}>
                      <BaseTD className="bg-whitex bg-celcomsrgray-lightgrey2 font-bold text-red-600 whiteborder">
                        {t("HomeBody.Promo.Table.Th3")}
                      </BaseTD>
                      <BaseTD className="bg-whitex bg-celcomsrgray-lightgrey2 font-bold text-red-600 text-center whiteborder">
                        {"RM 10"}
                      </BaseTD>
                      <BaseTD className="bg-whitex bg-celcomsrgray-lightgrey2 font-bold text-red-600 text-center whiteborder">
                        {"RM 30"}
                      </BaseTD>
                      <BaseTD className="bg-whitex bg-celcomsrgray-lightgrey2 font-bold text-red-600 text-center whiteborder">
                        {"RM 60"}
                      </BaseTD>
                      <BaseTD className="bg-whitex bg-celcomsrgray-lightgrey2 font-bold text-red-600 text-center whiteborder">
                        {"RM 110"}
                      </BaseTD>
                      <BaseTD className="bg-whitex bg-celcomsrgray-lightgrey2 font-bold text-red-600 text-center whiteborder">
                        {"RM 180"}
                      </BaseTD>
                      <BaseTD className="bg-whitex bg-celcomsrgray-lightgrey2 font-bold text-red-600 text-center whiteborder">
                        {"RM 250"}
                      </BaseTD>
                    </BaseTR>
                  </BaseTable>
                </>
              }
              <div className="mt-8 text-2xl text-left">
                <Translate i18nKey="HomeBody.ArticleTwo.Table.TableFooter1">
                  <TextMedium>
                    Please note that the Repair Fee excludes 6% sales and
                    service tax, which will be separately itemized in our
                    invoice and payable by you (if applicable).
                  </TextMedium>
                </Translate>
                {isCelcomCampaignInactive
                  ? <></>
                  : <p className="mt-5 font-bold">
                    <Translate i18nKey="HomeBody.Promo.Table.TableFooter">
                      <TextMedium>
                        *The actual discount varies according to the Tier.
                      </TextMedium>
                    </Translate>
                    <br/>
                    <span className="block">
                      <Translate i18nKey="HomeBody.Promo.DiscountAppliedNote">
                        <TextMedium>
                        The discounted fee will appear at the payment selection page.
                        </TextMedium>
                      </Translate>
                    </span>
                  </p>
                }
              </div>
            </div>
            <div className="xl:hidden">
              {isCelcomCampaignInactive
                ? <>
                  <BaseTable>
                    <BaseTR key={"1"}>
                      <BaseTD className="w-40 text-center text-white bg-black whiteborder">
                        {t("HomeBody.ArticleTwo.Table.Th1")}
                      </BaseTD>
                      <BaseTD className="text-center text-white bg-celcomprimary-dark whiteborder" style={{ width: '50%', minWidth: 'unset' }}>
                        {t("HomeBody.ArticleTwo.Table.Th2")}
                      </BaseTD>
                    </BaseTR>

                    <BaseTR key={"2"}>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {"0-500"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-throughx whiteborder" style={{ width: '50%', minWidth: 'unset' }}>
                        {"RM 30"}
                      </BaseTD>
                    </BaseTR>
                    <BaseTR key={"3"}>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {"501-1000"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-throughx whiteborder" style={{ width: '50%', minWidth: 'unset' }}>
                        {"RM 60"}
                      </BaseTD>
                    </BaseTR>
                    <BaseTR key={"4"}>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {"1001-1500"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-throughx whiteborder" style={{ width: '50%', minWidth: 'unset' }}>
                        {"RM 110"}
                      </BaseTD>
                    </BaseTR>
                    <BaseTR key={"5"}>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {"1501-3000"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-throughx whiteborder" style={{ width: '50%', minWidth: 'unset' }}>
                        {"RM 220"}
                      </BaseTD>
                    </BaseTR>
                    <BaseTR key={"6"}>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {"3001-6000"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-throughx whiteborder" style={{ width: '50%', minWidth: 'unset' }}>
                        {"RM 350"}
                      </BaseTD>
                    </BaseTR>
                    <BaseTR key={"7"}>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {">6000"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-throughx whiteborder" style={{ width: '50%', minWidth: 'unset' }}>
                        {"RM 500"}
                      </BaseTD>
                    </BaseTR>
                  </BaseTable></>
                : <>
                  <BaseTable>
                    <BaseTR key={"1"}>
                      <BaseTD className="w-40 text-center text-white bg-black whiteborder">
                        {t("HomeBody.ArticleTwo.Table.Th1")}
                      </BaseTD>
                      <BaseTD className="text-center text-white bg-celcomprimary-dark whiteborder" style={{ width: '30%', minWidth: 'unset' }}>
                        {t("HomeBody.ArticleTwo.Table.Th2")}
                      </BaseTD>
                      <BaseTD className="text-center bg-whitex bg-celcomsrgray-lightgrey2 text-red-700 font-bold whiteborder" style={{ width: '30%', minWidth: 'unset' }}>
                        {t("HomeBody.Promo.Table.Th3")}
                      </BaseTD>
                    </BaseTR>

                    <BaseTR key={"2"}>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {"0-500"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-throughx whiteborder" style={{ width: '30%', minWidth: 'unset' }}>
                        {"RM 30"}
                      </BaseTD>
                      <BaseTD className="text-center bg-whitex bg-celcomsrgray-lightgrey2 text-red-700 font-bold whiteborder" style={{ width: '30%', minWidth: 'unset' }}>
                        {"RM 10"}
                      </BaseTD>
                    </BaseTR>
                    <BaseTR key={"3"}>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {"501-1000"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-throughx whiteborder" style={{ width: '30%', minWidth: 'unset' }}>
                        {"RM 60"}
                      </BaseTD>
                      <BaseTD className="text-center bg-whitex bg-celcomsrgray-lightgrey2 text-red-700 font-bold whiteborder" style={{ width: '30%', minWidth: 'unset' }}>
                        {"RM 30"}
                      </BaseTD>
                    </BaseTR>
                    <BaseTR key={"4"}>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {"1001-1500"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-throughx whiteborder" style={{ width: '30%', minWidth: 'unset' }}>
                        {"RM 110"}
                      </BaseTD>
                      <BaseTD className="text-center bg-whitex bg-celcomsrgray-lightgrey2 text-red-700 font-bold whiteborder" style={{ width: '30%', minWidth: 'unset' }}>
                        {"RM 60"}
                      </BaseTD>
                    </BaseTR>
                    <BaseTR key={"5"}>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {"1501-3000"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-throughx whiteborder" style={{ width: '30%', minWidth: 'unset' }}>
                        {"RM 220"}
                      </BaseTD>
                      <BaseTD className="text-center bg-whitex bg-celcomsrgray-lightgrey2 text-red-700 font-bold whiteborder" style={{ width: '30%', minWidth: 'unset' }}>
                        {"RM 110"}
                      </BaseTD>
                    </BaseTR>
                    <BaseTR key={"6"}>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {"3001-6000"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-throughx whiteborder" style={{ width: '30%', minWidth: 'unset' }}>
                        {"RM 350"}
                      </BaseTD>
                      <BaseTD className="text-center bg-whitex bg-celcomsrgray-lightgrey2 text-red-700 font-bold whiteborder" style={{ width: '30%', minWidth: 'unset' }}>
                        {"RM 180"}
                      </BaseTD>
                    </BaseTR>
                    <BaseTR key={"7"}>
                      <BaseTD className="text-center text-white bg-black whiteborder">
                        {">6000"}
                      </BaseTD>
                      <BaseTD className="text-center text-black bg-celcomsrgray-lightgrey line-throughx whiteborder" style={{ width: '30%', minWidth: 'unset' }}>
                        {"RM 500"}
                      </BaseTD>
                      <BaseTD className="text-center bg-whitex bg-celcomsrgray-lightgrey2 text-red-700 font-bold whiteborder" style={{ width: '30%', minWidth: 'unset' }}>
                        {"RM 250"}
                      </BaseTD>
                    </BaseTR>
                  </BaseTable></>
              }
              <div className="mt-8 text-2xl text-left">
                <Translate i18nKey="HomeBody.ArticleTwo.Table.TableFooter1">
                  <TextMedium>
                    Please note that the Repair Fee excludes 6% sales and
                    service tax, which will be separately itemized in our
                    invoice and payable by you (if applicable).
                  </TextMedium>
                </Translate>                
                {isCelcomCampaignInactive
                  ? <></>
                  : <p className="mt-5 font-bold">
                    <Translate i18nKey="HomeBody.Promo.Table.TableFooter">
                      <TextMedium>
                        *The actual discount varies according to the Tier.
                      </TextMedium>
                    </Translate>
                    <br/>
                    <span className="block">
                      <Translate i18nKey="HomeBody.Promo.DiscountAppliedNote">
                        <TextMedium>
                        The discounted fee will appear at the payment selection page.
                        </TextMedium>
                      </Translate>
                    </span>                    
                  </p>
                }
              </div>
              {/* <div className="px-10 mt-4">
                <img src={SROffer} className="ml-5" />
              </div> */}
            </div>
            {/* <div className="hidden xl:block">
              <img src={SROffer} className="mx-auto ml-5" />
            </div> */}
          </div>
        </div>
      )}
    </article>
  );

  const startDateLabel = isSingtel ? moment(surCampaignStart).format("D MMM") : ""
  const endDateLabel = isSingtel ? moment(surCampaignEnd).format("D MMM") : ""
  const yearLabel = isSingtel ? moment(surCampaignEnd).format("YYYY") : ""

  return (
    <div className="flex flex-col">
      <Banner />
      <Services />
      <RepairService />

      {isSingtel && showSGCampaignPopup &&
        <div className={`block z-50`}>
          <BasePopup
            show={true}
            onClose={() => setShowSGCampaignPopup(false)}
            className="w-screen h-screen sm:rounded-3xl sm:w-640px sm:h-auto"
            global
          >

            <div className="mt-0 mx-auto text-center relative h-90% sm:h-auto">

              <div className="flex sm:hidden absolutex flex-col items-center justify-center text-14px leading-tight text-white rounded rounded-full"
                style={{ top: "0", left: "0", width: "110px", height: "110px", background: "#8223D2" }}>
                <Translate i18nKey={`Campaign.BadgeLabel`}>
                  <span></span>
                </Translate>
              </div>

              <div className="hidden sm:flex absolute flex-col items-center justify-center text-14px leading-tight text-white rounded rounded-full"
                style={{ top: "-20px", width: "110px", height: "110px", background: "#8223D2" }}>
                <Translate i18nKey={`Campaign.BadgeLabel`}>
                  <span></span>
                </Translate>
              </div>

              <div className="absolutex pt-6 sm:pt-0 sm:static transform top-1/2 -translate-y-1/2x sm:translate-y-0">
                <div className="flex justify-center items-center mb-6 pt-10px sm:pt-60px w-90% sm:w-1/2 mx-auto">

                  <p className="mt-0 text-5xl font-bold text-center ml-auto inline-block leading-tight">
                    <span style={{ paddingRight: "15px" }} className="block w-auto sm:w-270px" dangerouslySetInnerHTML={{ __html: t("Campaign.Title") }}></span>
                  </p>

                  <div className="mr-auto">
                    <div style={{ width: "75px" }}>
                      <img className="mr-auto" src={surIcon} style={{ width: "75px" }} />
                    </div>
                  </div>

                </div>


                <p className="text-center text-14px px-5 sm:px-0 mx-auto">
                  <Translate i18nKey={`Campaign.SubtitleLine1`}>
                  </Translate>
                </p>

                <p className="text-center text-14px px-5 sm:px-0 mx-auto">
                  <Translate i18nKey={`Campaign.SubtitleLine2`}>
                  </Translate>
                </p>

                <p className="text-center text-2xl px-5 sm:px-0 my-6 mx-auto font-bold" style={{ color: "#8223D2" }}>
                  <Translate i18nKey={`Campaign.DiscountNote`}>
                  </Translate>
                </p>

                <p className="text-center text-12px px-2 sm:px-5 pb-4" style={{ color: "#A5AAAF" }}>
                  {t("Campaign.Note", { startDate: startDateLabel, endDate: endDateLabel, year: yearLabel })}
                </p>
              </div>

            </div>

          </BasePopup>
        </div>
      }

      {showTutorialPopup &&
        <div className="block">
          <BasePopup
            show={true}
            onClose={() => setShowTutorialPopup(false)}
            className={`h-screen xl:h-90% w-screen xl:w-60% overflow-hidden pb-8 ${isSingtel ? "" : "celcomdigi"}`}
            global
          >
            <iframe
              src={t("SRTutorialURL")}
              width="100%"
              className="block h-85%"
              frameBorder="0"
              allow-scripts="true"
            />
            <BaseCard className="p-0 my-0 border-none shadow-none ">
              <BaseButtonGroup>
                <BaseButton
                  text="Close"
                  focused
                  onClick={() => setShowTutorialPopup(false)}
                  className={`mt-8 ${isSingtel ? "" : "celcomdigi"}`}
                />
              </BaseButtonGroup>
            </BaseCard>
          </BasePopup>
        </div>
      }

      <div className={`${showPopup ? "block" : "hidden"} z-50`}>
        <BasePopup
          show={true}
          onClose={() => setShowPopup(false)}
          className={`h-screen xl:h-90% w-screen xl:w-60% overflow-hidden pb-8 ${isSingtel ? "" : "celcomdigi"}`}
          global
        >
          <iframe
            src={PrepareLink}
            width="100%"
            className="block h-85%"
            frameBorder="0"
            allow-scripts="true"
          />
          <BaseCard className="p-0 my-0 border-none shadow-none ">
            <BaseButtonGroup>
              <BaseButton
                text="Close"
                focused
                onClick={() => setShowPopup(false)}
                className={`mt-8 ${isSingtel ? "" : "celcomdigi"}`}
              />
            </BaseButtonGroup>
          </BaseCard>
        </BasePopup>
      </div>
      <div
        className={`${showDeliveryTimeTablePopup ? "block" : "hidden"} z-50`}
      >
        <BasePopup
          show={true}
          onClose={() => setShowDeliveryTimeTablePopup(false)}
          className="w-screen h-screen overflow-auto rounded-lg xl:h-auto xl:w-1/2"
          global
        >
          <div className="mt-4 text-5xl">
            <Translate i18nKey="">
              <TextMedium>Delivery Time Table</TextMedium>
            </Translate>
          </div>
          <table className="w-full text-2xl">
            <tr className="font-bold " style={{ lineHeight: "60px" }}>
              <td>Time the request is submitted</td>
              <td>Pick-up time (Mon – Fri)</td>
              <td>Return Time (Mon – Sat)</td>
            </tr>

            <tr className="" style={{ lineHeight: "30px" }}>
              <td className="font-bold">Before 2pm, Mon - Fri</td>
              <td>By 4pm, same business day</td>
              <td>2 days* from pick-up day</td>
            </tr>
            <tr className="" style={{ lineHeight: "30px" }}>
              <td className="font-bold">After 2pm, Mon - Thu</td>
              <td>Before 4pm, next business day</td>
              <td>2 days from pick-up day</td>
            </tr>
            <tr className="" style={{ lineHeight: "30px" }}>
              <td className="font-bold">
                After 2pm on Fri and
                <br /> Whole day on Sat and Sun
              </td>
              <td>Before 4pm, next business day</td>
              <td>2 days from pick-up day</td>
            </tr>
          </table>
          <div className="mt-8 text-default">
            <Translate i18nKey="">
              <TextMedium>
                *The Business day are Monday to Fri exclude public holidays.
                Mobile phones picked up on Friday will be returned on Tue. No
                pick up service on Sundays and public holidays
              </TextMedium>
            </Translate>
          </div>
          <BaseCard className="p-0 my-0 border-none shadow-none ">
            <BaseButtonGroup>
              <BaseButton
                text="Close"
                focused
                onClick={() => setShowDeliveryTimeTablePopup(false)}
                className="mt-8"
              />
            </BaseButtonGroup>
          </BaseCard>
        </BasePopup>
      </div>
      <div className={`${showProgramsPopup ? "block" : "hidden"} z-50`}>
        <BasePopup
          show={true}
          onClose={() => setShowProgramsPopup(false)}
          className="w-screen h-screen overflow-auto rounded-lg xl:h-auto xl:w-1/2"
          global
        >
          <div className="mt-4 text-5xl">
            <Translate i18nKey="">
              <TextMedium>Type of Programs</TextMedium>
            </Translate>
          </div>
          <div>
            <Translate i18nKey="">
              <p className="text-2xl text-srgray-neutral">
                Check your Program for supportable Mobile Phone Brands and
                Screen Repair Entitlements
              </p>
            </Translate>
          </div>
          <div className="flex flex-col pt-8 xl:flex-row justify-evenly">
            <div className="flex flex-col">
              <div>
                <img
                  className="mx-auto mt-4 mb-auto"
                  src={IconPhoneDesign1}
                  alt=""
                />
                <div className="flex justify-center py-5 mx-auto text-2xl font-bold">
                  <Translate i18nKey="">
                    <TextMedium>MobileSwop Family</TextMedium>
                  </Translate>
                </div>
              </div>
              <div className="flex flex-row pb-6 text-2xl justify-evenly">
                <div className="pr-10">
                  <Translate i18nKey="">
                    <TextMedium>
                      Supported Mobile
                      <br /> Phone Brands
                    </TextMedium>
                  </Translate>
                </div>
                {<Translate i18nKey="">
                  <TextMedium>
                    Apple, Samsung,
                    <br /> Huawei, Oppo, Vivo,
                    <br /> Xiaomi, RealMe,
                    <br /> Google
                  </TextMedium>
                </Translate>}
                {/*<Translate i18nKey="">
                  <TextMedium>
                    Apple, Samsung,
                    <br /> Huawei, Oppo, Vivo,
                    <br /> RealMe, Google
                  </TextMedium>
              </Translate>*/}
              </div>
              <div className="flex flex-row text-2xl justify-evenly">
                <div className="pr-10">
                  <Translate i18nKey="">
                    <TextMedium>
                      Screen Repair
                      <br /> Entitlements
                    </TextMedium>
                  </Translate>
                </div>
                <Translate i18nKey="">
                  <TextMedium>
                    Unlimited Screen
                    <br /> Repair for all 3<br /> devices
                  </TextMedium>
                </Translate>
              </div>
            </div>
            <div className="flex flex-col">
              <div>
                <img
                  className="mx-auto mt-4 mb-auto"
                  src={IconPhoneDesign2}
                  alt=""
                />
                <div className="flex justify-center py-5 mx-auto text-2xl font-bold">
                  <Translate i18nKey="">
                    <TextMedium>Other MobileSwop</TextMedium>
                  </Translate>
                </div>
              </div>
              <div className="flex flex-row pb-6 text-2xl justify-evenly">
                <div className="pr-10">
                  <Translate i18nKey="">
                    <TextMedium>
                      Supported Mobile
                      <br /> Phone Brands
                    </TextMedium>
                  </Translate>
                </div>
                <Translate i18nKey="">
                  <TextMedium>
                    Apple and Samsung
                    <br /> only
                  </TextMedium>
                </Translate>
              </div>
              <div className="flex flex-row text-2xl justify-evenly">
                <div className="pr-10">
                  <Translate i18nKey="">
                    <TextMedium>
                      Screen Repair
                      <br /> Entitlements
                    </TextMedium>
                  </Translate>
                </div>
                <Translate i18nKey="">
                  <TextMedium>
                    1 x Screen Repair
                    <br /> every 12 months
                  </TextMedium>
                </Translate>
              </div>
            </div>
          </div>
          <BaseCard className="p-0 my-0 border-none shadow-none ">
            <BaseButtonGroup>
              <BaseButton
                text="Close"
                focused
                onClick={() => setShowProgramsPopup(false)}
                className="mt-8"
              />
            </BaseButtonGroup>
          </BaseCard>
        </BasePopup>
      </div>
    </div>
  );
};

HomeBody.propTypes = {
  onGetStarted: PropTypes.func.isRequired,
};

export default HomeBody;
